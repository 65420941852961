@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body ::-webkit-scrollbar, html ::-webkit-scrollbar {
  padding: 5px 5px 0 0;
  width: 5px;
  background-color: #2b282d;
}

body ::-webkit-scrollbar-thumb, html ::-webkit-scrollbar-thumb {
  background-color: rgb(100, 100, 100);
}

.wrapper {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  color: #fff;
}

.characters {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.characters__rows {
  max-height: 420px;
  border-radius: 0 20px 20px 0;
  overflow: hidden;
}

.characters__gender {
  background: #2b282d;
}

.characters__gender-type {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.characters__gender-type img {
  margin: 0px 10px;
  padding: 5px 20px;
  max-width: 90px;
  -o-object-fit: cover;
     object-fit: cover;
  background: #161616;
  border-radius: 25%;
  border: 2px solid #161616;
  -webkit-transition: border 0.25s ease;
  transition: border 0.25s ease;
}

.characters__gender-type img:hover {
  border: 2px solid #ffcb69;
}

.characters__gender-type span{
  cursor: pointer;
}

.characters__gender-type span.active img {
  background: #f29e00;
  border: 2px solid #f29e00;
}

.characters__data {
  background: #2b282d;
}

.characters__data-input {
  padding: 15px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.characters__data-input input {
  margin: 5px 0;
  padding: 10px;
  color: #fff;
  text-align: left;
  font-size: 15px;
  background: #161616;
  border-radius: 10px;
  border: 2px solid #161616;
  -webkit-transition: border 0.5s;
  transition: border 0.5s;
}

.characters__data-input input:focus {
  border: 2px solid #ffcb69;
}

.characters__cursor-info {
  padding: 15px 20px;
  text-align: center;
  background: rgba(0, 0, 0, 0.75);
}

.characters__categories {
  margin: 0 0 0 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.characters__categories-type {
  margin: 0 0 5px;
  border-radius: 50%;
  background: #161616;
  border: 3px solid #161616;
  overflow: hidden;
  -webkit-transition: border 0.25s;
  transition: border 0.25s;
}

.characters__categories-type img {
  padding: 10px;
  max-width: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: background 0.25s;
  transition: background 0.25s;
}

.characters__categories-type:hover {
  border: 3px solid #f29e00;
}

.characters__categories-type.active {
  border: 3px solid #f29e00;
  background: #f29e00;
}

.characters__parent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.characters__leftBar {
  max-width: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  background: #161616;
}

.characters__leftBar span {
  cursor: pointer;
  padding: 10px 5px;
}

.characters__leftBar img {
  padding: 5px;
  max-width: 50px;
  -o-object-fit: cover;
      object-fit: cover;
  border: 2px solid #161616;
  border-radius: 50%;
  -webkit-transition: border 0.25s ease;
  transition: border 0.25s ease;
}

.characters__leftBar img:hover {
  border: 2px solid #f29e00;
}

.characters__leftBar span.active img {
  border: 2px solid #f29e00;
  background: #f29e00;
}

.characters__parants {
  background: #2b282d;
}

.characters__parants-title {
  background: #161616;
}

.characters__parants-list {
  padding: 15px;
  max-height: 300px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: (1fr);
      grid-template: 1fr/repeat(3, 1fr);
  grid-auto-flow: row;
  overflow-y: scroll;
}

.characters__parants-list span {
  cursor: pointer;
  padding: 5px;
}

.characters__parants-list img {
  width: 80px;
  height: 90px;
  background: #161616;
  -webkit-transition: background 0.5s ease;
  transition: background 0.5s ease;
  object-fit: cover;
}

.characters__parants-list img:hover {
  background: #f29e00;
}
.characters__parants-list span.active img {
  background: #f29e00;
}

.characters__gene {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.characters__genes {
  min-height: 420px;
  background: #2b282d;
  overflow-y: scroll;
}

.characters__genes-rows {
  width: 250px;
}

.additionally__parent {
  background: #2b282d;
}

.characters__style{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.characters__styles {
  background: #2b282d;
}

.characters__styles-title {
  background: #161616;
}

.characters__styles-rows {
  width: 250px;
}

.characters__styles-list {
  padding: 15px;
  max-height: 300px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: (1fr);
      grid-template: 1fr/repeat(3, 1fr);
  grid-auto-flow: row;
  overflow-y: scroll;
}

.characters__styles-list span {
  cursor: pointer;
  padding: 5px;
}

.characters__styles-list img {
  width: 80px;
  height: 90px;
  background: #161616;
  -webkit-transition: background 0.5s ease;
  transition: background 0.5s ease;
  object-fit: cover;
}

.characters__styles-list img:hover {
  background: #f29e00;
}
.characters__styles-list span.active img {
  background: #f29e00;
}

.additionally__style{
  background: #2b282d;
}

.additionally__style-title{
  background: #161616;
}

.additionally__style-colors{
  margin: 0 0 10px 0;
  padding: 15px;
  max-height: 130px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: (1fr);
      grid-template: 1fr/repeat(3, 1fr);
  grid-auto-flow: row;
  overflow-y: scroll;
}

.additionally__style-color{
  width: 30px;
  height: 30px;

  margin: 2px;
  cursor: pointer;
}

.additionally__style-color:hover{
  border: 2px solid #ffcb69;
}

.additionally__style-color.active{
  border: 2px solid #f29e00;
}

.characters__disadvantage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.characters__disadvantage {
  min-height: 420px;
  background: #2b282d;
  overflow-y: scroll;
}

.characters__disadvantage-rows {
  width: 250px;
}

.characters__disadvantage-title {
  background: #161616;
}

.characters__clothe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.characters__clothes {
  background: #2b282d;
}

.characters__clothes-title {
  background: #161616;
}

.characters__clothes-list {
  padding: 15px;
  max-height: 300px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: (1fr);
      grid-template: 1fr/repeat(3, 1fr);
  grid-auto-flow: row;
  overflow-y: scroll;
}

.characters__clothes-list span {
  cursor: pointer;
  padding: 5px;
}

.characters__clothes-list img {
  width: 80px;
  height: 90px;
  background: #161616;
  -webkit-transition: background 0.5s ease;
  transition: background 0.5s ease;
  object-fit: cover;
}

.characters__clothes-list img:hover {
  background: #f29e00;
}
.characters__clothes-list span.active img {
  background: #f29e00;
}

.title {
  padding: 10px 15px;
  background: #161616;
  text-align: center;
  font-size: 15px;
}

.button {
  position: absolute;
  bottom: 25px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  cursor: pointer;
}

.button span {
  padding: 10px 45px;
  color: #fff;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.75);
  border: 2px solid #161616;
  border-radius: 15px;
}

.range {
  padding: 15px 25px 10px;
}

.range p {
  text-align: center;
}

.range input {
  margin: 0 0 15px;
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #161616;
  outline: none;
}

.range input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffcb69;
  cursor: pointer;
}

.value{
  padding: 0 0 10px;
}

.value p{
  text-align: center;
}