@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

.wrapper {
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box {
  width: 500px;
  border-radius: 25px;
  background: #191919;
  padding: 20px;
}

.box__rows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box__subtitle p {
  color: #fff;
  text-align: center;
  margin: 0 0 7px;
}

.box__link {
  margin: 0 0 20px;
}

.box__link ul {
  display: flex;
  align-items: center;
}

.box__link a {
  font-size: 20px;
  font-weight: 400;
  color: #9e9e9e;
  margin: 0 5px;
  transition: 0.5s;
}

.box__link a:hover {
  color: #fff;
}

.box__link a.active {
  border-bottom: 2px solid #ffcb69;
  color: #fff;
}

.box__logo img {
  width: 500px;
  height: 150px;
  object-fit: cover;
}

.box__form {
  display: flex;
  flex-direction: column;
  color: #fff;
}

.box__form span {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin: 0 0 15px 0;
}

.box__form input {
  border: 0;
  background: none;
  display: block;
  margin: 10px auto;
  text-align: center;
  border: 2px solid #9e9e9e;
  padding: 14px 10px;
  outline: none;
  color: white;
  border-radius: 24px;
  transition: 0.25s;
  width: 200px;
}

.box__form input:focus {
  width: 280px;
  border: 2px solid #69ffcb;
}

.box__form button {
  margin: 0 0 15px 0;
  border: 0;
  background: none;
  margin: 10px auto;
  text-align: center;
  border: 2px solid #ffcb69;
  padding: 14px 25px;
  outline: none;
  color: #fff;
  border-radius: 24px;
  transition: 0.25s;
  font-size: 14px;
  width: 150px;
}

.box__form button:hover {
  background: #ffcb69;
  color: black;
}

.box__error-mess{
  padding: 5px 0;
  color: #ff8069;
  font-size: 12px;
  text-align: center;
  transition: 0.25s;
}
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

.wrapper {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
}

.header__exit {
  padding: 7px;
  background: #ffcb69;
  border-radius: 25px;
}

.header__exit span{
  cursor: pointer;
}

.header__exit img {
  width: 30px;
  object-fit: cover;
}

.header__donat-stats {
  text-align: right;
}

.header__donat-info {
  font-size: 16px;
}

.header__donate-server {
  font-size: 18px;
}

.main__characters {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main__character {
  width: 300px;
  height: 450px;
  margin: 0 10px 0 0;
  position: relative;
  color: #fff;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.9);
}

.main__character:last-child {
  margin: 0;
}

.main__character-header {
  display: flex;
  align-items: center;
}

.main__character-icons img {
  height: 25px;
  background: #ffcb69;
  object-fit: cover;
}

.main__character-title {
  margin: 5px 0 0 20px;
  font-size: 14px;
  text-transform: uppercase;
}

.main__character-name {
  position: absolute;
  top: 45px;
  left: 25px;
  font-size: 24px;
}

.main__character-surname {
  position: absolute;
  top: 70px;
  left: 25px;
  font-size: 22px;
  color: rgba(255, 255, 255, 0.7);
}

.main__character-link {
  position: absolute;
  display: block;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%);
  border: 2px solid #ffdd9c;
  border-radius: 15px;
}

.main__character-link span {
  display: block;
  padding: 20px 80px;
  color: #fff;
  text-transform: uppercase;
}

.main__character-link span:hover {
  cursor: pointer;
}
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body ::-webkit-scrollbar, html ::-webkit-scrollbar {
  padding: 5px 5px 0 0;
  width: 5px;
  background-color: #2b282d;
}

body ::-webkit-scrollbar-thumb, html ::-webkit-scrollbar-thumb {
  background-color: rgb(100, 100, 100);
}

.wrapper {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  color: #fff;
}

.characters {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  display: flex;
}

.characters__rows {
  max-height: 420px;
  border-radius: 0 20px 20px 0;
  overflow: hidden;
}

.characters__gender {
  background: #2b282d;
}

.characters__gender-type {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.characters__gender-type img {
  margin: 0px 10px;
  padding: 5px 20px;
  max-width: 90px;
  object-fit: cover;
  background: #161616;
  border-radius: 25%;
  border: 2px solid #161616;
  transition: border 0.25s ease;
}

.characters__gender-type img:hover {
  border: 2px solid #ffcb69;
}

.characters__gender-type span{
  cursor: pointer;
}

.characters__gender-type span.active img {
  background: #f29e00;
  border: 2px solid #f29e00;
}

.characters__data {
  background: #2b282d;
}

.characters__data-input {
  padding: 15px 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.characters__data-input input {
  margin: 5px 0;
  padding: 10px;
  color: #fff;
  text-align: left;
  font-size: 15px;
  background: #161616;
  border-radius: 10px;
  border: 2px solid #161616;
  transition: border 0.5s;
}

.characters__data-input input:focus {
  border: 2px solid #ffcb69;
}

.characters__cursor-info {
  padding: 15px 20px;
  text-align: center;
  background: rgba(0, 0, 0, 0.75);
}

.characters__categories {
  margin: 0 0 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.characters__categories-type {
  margin: 0 0 5px;
  border-radius: 50%;
  background: #161616;
  border: 3px solid #161616;
  overflow: hidden;
  transition: border 0.25s;
}

.characters__categories-type img {
  padding: 10px;
  max-width: 50px;
  object-fit: cover;
  transition: background 0.25s;
}

.characters__categories-type:hover {
  border: 3px solid #f29e00;
}

.characters__categories-type.active {
  border: 3px solid #f29e00;
  background: #f29e00;
}

.characters__parent {
  display: flex;
  height: 100%;
}

.characters__leftBar {
  max-width: 60px;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  background: #161616;
}

.characters__leftBar span {
  cursor: pointer;
  padding: 10px 5px;
}

.characters__leftBar img {
  padding: 5px;
  max-width: 50px;
  object-fit: cover;
  border: 2px solid #161616;
  border-radius: 50%;
  transition: border 0.25s ease;
}

.characters__leftBar img:hover {
  border: 2px solid #f29e00;
}

.characters__leftBar span.active img {
  border: 2px solid #f29e00;
  background: #f29e00;
}

.characters__parants {
  background: #2b282d;
}

.characters__parants-title {
  background: #161616;
}

.characters__parants-list {
  padding: 15px;
  max-height: 300px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: (1fr);
      grid-template: 1fr/repeat(3, 1fr);
  grid-auto-flow: row;
  overflow-y: scroll;
}

.characters__parants-list span {
  cursor: pointer;
  padding: 5px;
}

.characters__parants-list img {
  width: 80px;
  height: 90px;
  background: #161616;
  transition: background 0.5s ease;
  object-fit: cover;
}

.characters__parants-list img:hover {
  background: #f29e00;
}
.characters__parants-list span.active img {
  background: #f29e00;
}

.characters__gene {
  display: flex;
  height: 100%;
}

.characters__genes {
  min-height: 420px;
  background: #2b282d;
  overflow-y: scroll;
}

.characters__genes-rows {
  width: 250px;
}

.additionally__parent {
  background: #2b282d;
}

.characters__style{
  display: flex;
  height: 100%;
}

.characters__styles {
  background: #2b282d;
}

.characters__styles-title {
  background: #161616;
}

.characters__styles-rows {
  width: 250px;
}

.characters__styles-list {
  padding: 15px;
  max-height: 300px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: (1fr);
      grid-template: 1fr/repeat(3, 1fr);
  grid-auto-flow: row;
  overflow-y: scroll;
}

.characters__styles-list span {
  cursor: pointer;
  padding: 5px;
}

.characters__styles-list img {
  width: 80px;
  height: 90px;
  background: #161616;
  transition: background 0.5s ease;
  object-fit: cover;
}

.characters__styles-list img:hover {
  background: #f29e00;
}
.characters__styles-list span.active img {
  background: #f29e00;
}

.additionally__style{
  background: #2b282d;
}

.additionally__style-title{
  background: #161616;
}

.additionally__style-colors{
  margin: 0 0 10px 0;
  padding: 15px;
  max-height: 130px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: (1fr);
      grid-template: 1fr/repeat(3, 1fr);
  grid-auto-flow: row;
  overflow-y: scroll;
}

.additionally__style-color{
  width: 30px;
  height: 30px;

  margin: 2px;
  cursor: pointer;
}

.additionally__style-color:hover{
  border: 2px solid #ffcb69;
}

.additionally__style-color.active{
  border: 2px solid #f29e00;
}

.characters__disadvantage {
  display: flex;
  height: 100%;
}

.characters__disadvantage {
  min-height: 420px;
  background: #2b282d;
  overflow-y: scroll;
}

.characters__disadvantage-rows {
  width: 250px;
}

.characters__disadvantage-title {
  background: #161616;
}

.characters__clothe {
  display: flex;
  height: 100%;
}

.characters__clothes {
  background: #2b282d;
}

.characters__clothes-title {
  background: #161616;
}

.characters__clothes-list {
  padding: 15px;
  max-height: 300px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: (1fr);
      grid-template: 1fr/repeat(3, 1fr);
  grid-auto-flow: row;
  overflow-y: scroll;
}

.characters__clothes-list span {
  cursor: pointer;
  padding: 5px;
}

.characters__clothes-list img {
  width: 80px;
  height: 90px;
  background: #161616;
  transition: background 0.5s ease;
  object-fit: cover;
}

.characters__clothes-list img:hover {
  background: #f29e00;
}
.characters__clothes-list span.active img {
  background: #f29e00;
}

.title {
  padding: 10px 15px;
  background: #161616;
  text-align: center;
  font-size: 15px;
}

.button {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
}

.button span {
  padding: 10px 45px;
  color: #fff;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.75);
  border: 2px solid #161616;
  border-radius: 15px;
}

.range {
  padding: 15px 25px 10px;
}

.range p {
  text-align: center;
}

.range input {
  margin: 0 0 15px;
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #161616;
  outline: none;
}

.range input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffcb69;
  cursor: pointer;
}

.value{
  padding: 0 0 10px;
}

.value p{
  text-align: center;
}
