@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

.wrapper {
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.box {
  width: 500px;
  border-radius: 25px;
  background: #191919;
  padding: 20px;
}

.box__rows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.box__subtitle p {
  color: #fff;
  text-align: center;
  margin: 0 0 7px;
}

.box__link {
  margin: 0 0 20px;
}

.box__link ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.box__link a {
  font-size: 20px;
  font-weight: 400;
  color: #9e9e9e;
  margin: 0 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.box__link a:hover {
  color: #fff;
}

.box__link a.active {
  border-bottom: 2px solid #ffcb69;
  color: #fff;
}

.box__logo img {
  width: 500px;
  height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
}

.box__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: #fff;
}

.box__form span {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin: 0 0 15px 0;
}

.box__form input {
  border: 0;
  background: none;
  display: block;
  margin: 10px auto;
  text-align: center;
  border: 2px solid #9e9e9e;
  padding: 14px 10px;
  outline: none;
  color: white;
  border-radius: 24px;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  width: 200px;
}

.box__form input:focus {
  width: 280px;
  border: 2px solid #69ffcb;
}

.box__form button {
  margin: 0 0 15px 0;
  border: 0;
  background: none;
  margin: 10px auto;
  text-align: center;
  border: 2px solid #ffcb69;
  padding: 14px 25px;
  outline: none;
  color: #fff;
  border-radius: 24px;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  font-size: 14px;
  width: 150px;
}

.box__form button:hover {
  background: #ffcb69;
  color: black;
}

.box__error-mess{
  padding: 5px 0;
  color: #ff8069;
  font-size: 12px;
  text-align: center;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}