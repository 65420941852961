@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

.wrapper {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 0 20px;
}

.header__exit {
  padding: 7px;
  background: #ffcb69;
  border-radius: 25px;
}

.header__exit span{
  cursor: pointer;
}

.header__exit img {
  width: 30px;
  -o-object-fit: cover;
     object-fit: cover;
}

.header__donat-stats {
  text-align: right;
}

.header__donat-info {
  font-size: 16px;
}

.header__donate-server {
  font-size: 18px;
}

.main__characters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main__character {
  width: 300px;
  height: 450px;
  margin: 0 10px 0 0;
  position: relative;
  color: #fff;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.9);
}

.main__character:last-child {
  margin: 0;
}

.main__character-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main__character-icons img {
  height: 25px;
  background: #ffcb69;
  -o-object-fit: cover;
     object-fit: cover;
}

.main__character-title {
  margin: 5px 0 0 20px;
  font-size: 14px;
  text-transform: uppercase;
}

.main__character-name {
  position: absolute;
  top: 45px;
  left: 25px;
  font-size: 24px;
}

.main__character-surname {
  position: absolute;
  top: 70px;
  left: 25px;
  font-size: 22px;
  color: rgba(255, 255, 255, 0.7);
}

.main__character-link {
  position: absolute;
  display: block;
  bottom: 25px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  border: 2px solid #ffdd9c;
  border-radius: 15px;
}

.main__character-link span {
  display: block;
  padding: 20px 80px;
  color: #fff;
  text-transform: uppercase;
}

.main__character-link span:hover {
  cursor: pointer;
}